@import "../../../App.scss";

.testimonial-section {
  @include section-padding;

  @media (min-width: 1023px) and (max-width: 1440px) {
    padding-top: 0% !important;
  }
  
  .testimonial-text {
    @include sub-text;
  }

  .testimonial-say {
    @include title-text;
  }

  .carousel-section{
    background: #FFF0F7;
    border-radius: 20px;

    .carousel-control-prev {
      width: max-content;
    }

    .carousel-control-next {
      width: max-content;
    }
  }

  .carousel-control-next-icon {
    background-color: var(--ahsi-text-pink);
    border-radius: 50%;
    margin-left: 100px;
    @media (max-width: 1024px) {
      display: none;
    }
  }

  .carousel-control-prev-icon {
    background-color: var(--ahsi-text-pink);
    border-radius: 50%;
    margin-right: 100px;
    @media (max-width: 1024px) {
      display: none;
    }
  }

  .testimonial-carousel-section {
    width: 100%;

    @media (min-width: 1024px) {
      padding: 70px !important;
    }
  }
  
  .testimonial-carousel-section {
    padding: 3.6rem 7rem;

    @media screen {
      padding: 1rem 1rem;
      align-items: center;
    }

    .testimonial-text-comment {
      @include title-text;
      font-size: 24px !important;

      @media (max-width: 1024px) {
        font-size: 20px !important;
        line-height: 27px;
      }
    }

    .testimonial-review-star{
      width:89px;
    }

    .testimonial-review-name{
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.01em;
      opacity: 0.8;
      line-height: 22px;
    }
  }
}

.mt-98 {
  @media (min-width: 1024px) {
    margin-top: 98px;
  }
}