.section-over-header {
    font-family: 'DM Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    background-color: var(--ahsi-pink-background);
    height: 37px;

    a {
        color: var(--ahsi-text-black-95);

        &:hover {
            color: inherit;
        }
    }
}

.ahsi-header {
    height: 103px;

    .active {
        color: var(--ahsi-text-pink) !important;
    }

    .navbar-nav {
        @media (max-width: 991px) {
            align-items: center;
        }
    }

    .navbar-collapse {
        flex-grow: unset;
        flex-basis: unset;

        @media (max-width: 991px) {
            width: 100%;
            max-width: 360px;
            z-index: 2;
            background-color: var(--ahsi-pink-background);
            padding: 20px 10px;
            position: absolute;
            top: 0px;
            right: 65px;
            left: auto;

            @media (max-width: 500px) {
                right: 0px;
            }
        }
    }

    .phone-number {
        color: var(--ahsi-text-pink);
        float: right;
        padding-right: 1rem !important;
        padding-left: 1rem !important;

        @media (max-width: 992px) {
            padding-right: 0.5rem !important;
            padding-left: 0.5rem !important;
        }
    }

    .navbar-area {
        @media (max-width: 991px) {
            padding-top: 69px;
        }
    }

    .nav-link {
        padding-right: 1rem !important;
        padding-left: 1rem !important;

        @media (max-width: 992px) {
            font-family: 'Recoleta';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 27px;
        }
    }

    .pl-0 {
        padding-left: 0 !important;
    }

    .header-phone {
        flex-direction: column;

        @media (max-width: 991px) {
            flex-direction: row !important;
        }
    }
}

.lh-10 {
    line-height: 10px;
    @media (max-width: 991px) {
        line-height: unset;
    }
}

.mr-1 {
    margin-right: 4px;
}

.ml-1 {
    margin-left: 4px;
}

.solid-pink {
    margin-top: 24px;
    margin-bottom: 24px;
    width: 75%;
    background-color: var(--ahsi-text-pink);

    @media (min-width: 992px) {
        display: none;
    }
}

.cross-sign {
    @media (max-width: 991px) {
        float: right;
        background-color: white;
        width: 40px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 5px;
        position: absolute;
        right: 29px;
        top: 27px;
        display: flex;

        .cross-text {
            color: var(--ahsi-text-pink);
        }
    }
}