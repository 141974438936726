@import "../../../App.scss";

.misc-section {
    padding: 3.27% 7.57% 4.24% 8%;
    box-shadow: 0px 4px 20px rgba(245, 95, 163, 0.1);
    max-width: 920px;
    margin-right: auto;
    margin-left: auto;

    .misc-detail-section {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: justify;
        letter-spacing: 0.01em;
    }
}