@import '../../../App.scss';

.core-values-section {
    background: var(--ahsi-pink-background);
    @include section-padding;

    .core-values-title-section {
        .title {
            font-family: 'Recoleta';
            font-weight: 400;
            font-size: 36px;
            line-height: 49px;
            color: var(--ahsi-text-black);
        }
    }

    .core-values-detail-section {
        margin-top: 70px;
        justify-content: space-between;

        .value {
            max-width: 363px;

            .mb-15 {
                margin-bottom: 15px;
            }

            .value-number {
                font-family: 'Recoleta';
                font-weight: 400;
                font-size: 24px;
                line-height: 33px;
                text-decoration-line: underline;
                color: var(--ahsi-text-pink);
                margin-right: 15px;
            }

            .value-title {
                font-family: 'Recoleta';
                font-weight: 400;
                font-size: 24px;
                line-height: 33px;
                color: var(--ahsi-text-black);
            }
        }
    }
}