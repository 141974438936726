@import '../../../App.scss';

.contact-detail-section {
    @include section-padding;

    .contact-detail-sub-section {
        .sub-section-pink-text {
            font-weight: 400;
            line-height: 22px;
            @include sub-text;
        }

        .sub-section-text {
            @include title-text;
            margin-top: 13px;
            font-weight: 400;
            line-height: 49px;
        }
    }

    .contact-section {
        margin-top: 92px;

        .contact-sub-section {
            @media (min-width: 1024px) {
                margin-right: 100px;
            }

            .contact-details-title {
                @include title-text;
                font-weight: 400;
                line-height: 33px;
                margin-bottom: 30px;
            }

            .contact-email-phone {
                @include paragraph-text;
                font-weight: 400;
                line-height: 22px;
                margin-bottom: 50px;

                @media (max-width: 1023px) {
                    margin-bottom: 30px;
                }

                span > img {
                    margin-right: 19px;
                }

                img {
                    margin-right: 19px;
                }

                .mb-30 {
                    margin-bottom: 30px;
                }
            }
        }
    }

    .ml-60 {
        margin-left: 60px;

        @media (max-width: 1024px) {
            margin-left: 30px;
        }
    }
}