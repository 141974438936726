@import "../../App.scss";
.membership-section {
  background: url(../../assets/images/membership-bg.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  
  @media (min-width: 1025px) {
    height: 900px;
  }
  margin-bottom: -100px;

  @include section-padding;

  @media (max-width: 1024px) {
    padding-bottom: 100px !important;
  }

  .membership-sub-heading {
    @include sub-text;
  }
  .membership-heading {
    @include title-text;
    font-size: 36px !important;
    line-height: 49px !important;
  }
  .carousel-indicators {
    margin-top: 30px;
  }
  @media (min-width: 1024px) {
    .carousel-indicators {
      bottom: -100px;
    }
  }
  .carousel-indicators button {
    background-color: var(--ahsi-text-pink);
    height: 15px;
    width: 16px;
  }
  
  .card-plan {
      @media (max-width: 1024px) {
        height: 560px;
        width: 357px;
      }
    }

    .carousel-indicators {
      @media (max-width: 1024px) {
        bottom: -190px;
      }
    }

    .carousel-indicators button {
      @media (max-width: 1024px) {
        margin-top: -320px;
      }
    }
}
