@import "../../../App.scss";
.vision-section {
  @include section-padding;

  .vision-image-section {
    img {
      @media (max-width: 1024px) {
        width: 313px;
        height: 319px;
        margin-bottom: 38px;
      }
    }
  }
}
.vision-heading-text {
  @include title-text;
}
.vision-text {
  @include paragraph-text;
}

.learn-more-btn {
  @include learn-more-btn;
}

.sub-section-pink-text {
  @include sub-text;
}

.mr-5 {
  margin-right: 25px;
}

.ml-5 {
  margin-left: 25px;
}
