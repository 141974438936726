@import "../../../App.scss";


.about-detail-section-img {
    background: -webkit-linear-gradient(rgba(#FDDFED, 0.8), rgba(#FDDFED, 0.8)), url("../../../assets/images/about-detail-section-img.png");
    background: linear-gradient(rgba(#FDDFED, 0.8), rgba(#FDDFED, 0.8)), url("../../../assets/images/about-detail-section-img.png");
  }
  
  .feedback-detail-section-img {
    background: -webkit-linear-gradient(rgba(#FDDFED, 0.8), rgba(#FDDFED, 0.8)), url("../../../assets/images/feedback-detail-section-img.png");
    background: linear-gradient(rgba(#FDDFED, 0.8), rgba(#FDDFED, 0.8)), url("../../../assets/images/feedback-detail-section-img.png");
  }
  
  .services-detail-section-img {
    background: -webkit-linear-gradient(rgba(#FDDFED, 0.8), rgba(#FDDFED, 0.8)), url("../../../assets/images/services-detail-section-img.png");
    background: linear-gradient(rgba(#FDDFED, 0.8), rgba(#FDDFED, 0.8)), url("../../../assets/images/services-detail-section-img.png");
  }
  .whyahsi-detail-section-img {
    background: -webkit-linear-gradient(rgba(#FDDFED, 0.8), rgba(#FDDFED, 0.8)), url("../../../assets/images/whyahsi-detail-section-img.svg");
    background: linear-gradient(rgba(#FDDFED, 0.8), rgba(#FDDFED, 0.8)), url("../../../assets/images/whyahsi-detail-section-img.svg");
  }

  .blog-detail-section-img {
    background: -webkit-linear-gradient(rgba(#FDDFED, 0.8), rgba(#FDDFED, 0.8)), url("../../../assets/images/blog-detail-section-img.svg");
    background: linear-gradient(rgba(#FDDFED, 0.8), rgba(#FDDFED, 0.8)), url("../../../assets/images/blog-detail-section-img.svg");
    
  }

  .appointment-detail-section-img {
    background: -webkit-linear-gradient(rgba(#FDDFED, 0.8), rgba(#FDDFED, 0.8)), url("../../../assets/images/appointment-detail-section-img.png");
    background: linear-gradient(rgba(#FDDFED, 0.8), rgba(#FDDFED, 0.8)), url("../../../assets/images/appointment-detail-section-img.png");
  }

.pink-section {
    font-family: 'Recoleta';
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .navigation-breadcrumb {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;

        .breadcrumb {
            .active {
                a {
                    pointer-events: none;
                    color: var(--ahsi-text-pink);
                }
            }
        }
    }
}