@import "../../../App.scss";

.dots {
    span:first-child {
        margin-right: 15px;
    }

    .dot {
        width: 12px;
        height: 12px;
        background-color: var(--ahsi-pink-background);
        border-radius: 50%;
        display: inline-block;
    }

    .active {
        background-color: var(--ahsi-text-pink)
    }
}

.blogs-detail-section {
    @include section-padding;

    .blogs-detail-sub-section {
        .sub-section-pink-text {
            color: var(--ahsi-text-pink);
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
        }

        .small-download {
            color: black;
            font-weight: 400;
            font-size: 11px;
            line-height: 22px;
        }
        .sub-section-text {
            margin-top: 13px;
            font-family: 'Recoleta';
            font-style: normal;
            font-weight: 400;
            font-size: 36px;
            line-height: 49px;
            color: var(--ahsi-text-black);
        }
    }

    .blogs-section {
        margin-bottom: 100px;
        .carousel-indicators  .active
        {
            width:26px;
            background-color: #F55FA3;
           
        }
        .carousel-indicators{
            bottom:-100px;
        }
        .carousel-indicators button 
        {
            width: 12px;
            height: 6px;
            background-color: pink;
        }
    
        .blog {
            max-width: 445px;
    
            .blog-img {
                width: 90%;
                height: 90%;
                border-radius: 10px;
                margin-bottom: 45px;
                margin-top: 50px;
            }
        
            .blog-date {
                color: var(--ahsi-text-pink);
                font-family: 'DM Sans';
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: 0.01em;
                margin-bottom: 10px;
            }
        
            .blog-title {
                font-family: 'Recoleta';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 33px;
                max-width: 393px;
            }
        
            .blog-read-more {
                color: var(--ahsi-text-pink);
                text-decoration-line: underline;
                font-family: 'Recoleta';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 27px;
            }
        }
    }
}
