.btn-style {
  overflow: visible;
  width: 196.14px;
  height: 38px;
  color: #ffffff;
  border-radius: 33.5px;
  margin-right: 15px;
  padding-left: 10px; 
  padding-right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-decoration: underline;

  @media (max-width: 1024px) {
    width: 100%;
    max-width: 320px;
  }
}
