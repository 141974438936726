@import "../../App.scss";
.card-section {
  width: 400px;

  @media (max-width: 1024px) {
    width: 320px;
  }
  height: 400px;
  padding: 30px;

  @media (max-width: 1023px) {
    padding: 40px;
  }

  border-radius: 3%;
  background-color: white;
  .membership-price {
    width: 67%;
  }
  .membership-select-button {
    width: 300px;

    @media (max-width: 1024px) {
      width: 250px;
    }
    color: var(--ahsi-text-pink);
    border-radius: 10.5px;
    border-color: var(--ahsi-text-pink);
    background-color: transparent;
    padding: 8px;
  }
  .service-list {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
  }
  .membership-text {
    font-family: DM sans;
    font-size: 15px;
    font-style: normal;
    line-height: 23px;
  }

  .card-details {
    max-width: 320px;
  }
}

.text-pink {
  color: var(--ahsi-text-pink);
}

.modal-title {
  @include title-text;
}

.modal-body {
  @include paragraph-text;
}