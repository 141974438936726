.about-finding-solution-img {
  background: url("../../../assets/images/about-finding-solution-img.svg");
}

.feedback-finding-solution-img {
  background: url("../../../assets/images/feedback-finding-solution-img.svg");
}

.homepage-finding-solution-img {
  background: url("../../../assets/images/homepage-finding-solution-img.png");
}
@import "../../../App.scss";

.services-finding-solution-img {
  background: url("../../../assets/images/services-finding-solution-img.svg");
}
.whyahsi-finding-solution-img {
  background: url("../../../assets/images/whyahsi-finding-solution-img.svg");
}
.finding-solutions {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  @media (min-width: 1025px) {
    height: 457px;
  }
  .finding-solutions-section {
    @include section-padding;

    @media (max-width: 1024px) {
      padding: 50px 100px 50px 30px;
    }
    .text-sm {
      @include paragraph-text;
      font-weight: 400;
      line-height: 22px;
      color: #fff !important;
    }
    .text-lg {
      @include title-text;
      color: #fff !important;
      font-weight: 400;
      line-height: 49px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      @media (min-width: 1024px) {
        max-width: 547px;
      }
    }
  }

  .mt-35 {
    margin-top: 35px;
  }
}
