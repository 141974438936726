@import "../../../App.scss";
.feedback-section {
  @include section-padding;

  .feedback-sub-heading {
    @include sub-text;
  }
  .feed-buttons {
    margin-bottom: 65px;
  }
  .feedback-toggle-buttons {
    width: 152px;
    height: 38px;
    color: white;
    border-radius: 33.5px;
    background-color: var(--ahsi-text-pink);
    font-family: "DM sans";
    border-color: transparent;
  }
  .modal-success
  {
    width: 410px ;
  }
  .abc
  {
    color:#f55fa3
  }
  .button-border {
    border-color: transparent;
    background-color: #fff0f7;
  }
  .feedback-send-button {
    width: 190px;
    height: 38px;
    color: #014293;
    border-radius: 33.5px;
    background-color: white;
    font-family: "DM sans";
    border-color: var(--ahsi-text-pink);
  }
  .feedback-heading {
    @include title-text;
    font-size: 36px !important;
    max-width: 550px;
    line-height: 49px !important;
  }
  .feedback-label {
    @include paragraph-text;
    line-height: 18px !important;
  }
  input {
    width: 227px;
    border-style: none;
    opacity: 0.2;
    border-bottom: 2px solid #014293;
  }

  input:focus {
    outline: none;
    opacity: 1;
    border-bottom: 2px solid #f55fa3;
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1;
   }
  .feedback-comment {
    width: 490px;
  }
  
  @media only screen and (max-width: 1024px) {
    .form-responsive {
      justify-content: center;
      align-items: center;
    }
    .message-responsive {
      width: 300px;
    }
    input {
      width: 250px;
    }
    .feedback-comment {
      width: 250px;
    }
    .email-input-mobile
    {
      margin-top: 25px;
    }
  }
}
