@import '../../App.scss';
.project-section
{
    @include section-padding;

    .project-sub-heading
    {
        @include sub-text;
    }
    .project-heading
    {
        @include title-text;
        font-size: 36px !important;
        line-height: 49px !important;
    }
    .project-card
    {
        border: 1px solid #F55FA3;
        border-radius: 20px;
        width: 314px;
        height: 182px;
    }
    .project-image
    {
        width: 185px;
        height: 74px;
    }

    div:first-child {
        @media (max-width: 1024px) {
            margin-bottom: 25px;
        }
    }

    .mr-42 {
        margin-right: 42px;
    }
}