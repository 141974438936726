@import '../../App.scss';
.carousel-home-section{
  .carousel-control-next-icon, .carousel-control-prev-icon{
    visibility:hidden;
  }
  .carousel-indicators{
    visibility: hidden;
  }

  .carousel-control-prev {
    display: none;
  }

  @media (min-width:1024px)  {
    .carousel-indicators 
    {
      visibility: visible;
      display:flex;
      flex-direction: column;
      top:200px; 
      left:80%;
    }
    .carousel-indicators button
    {
      width:14px;
      height:15px;
      border-radius: 50%;
    }
    .carousel-indicators .active{
      width:12px;
      height:40px;
      border-radius: 30%;
      background-color: #F55FA3;
    }  
  }
 
}
.carousel-one-image{
  background: url("../../assets/images/baby-image.svg");
}
.carousel-two-image{
  background: -webkit-linear-gradient(rgba(#000, 0.15), rgba(#000, 0.15)), url("../../assets/images/hero-slide-2.png");
  background: linear-gradient(rgba(#000, 0.15), rgba(#000, 0.15)), url("../../assets/images/hero-slide-2.png");
}
.carousel-three-image{
  background: -webkit-linear-gradient(rgba(#000, 0.15), rgba(#000, 0.15)), url("../../assets/images/hero-slide-3.png");
  background: linear-gradient(rgba(#000, 0.15), rgba(#000, 0.15)), url("../../assets/images/hero-slide-3.png");
}

.contact-us-btn {
  @include contact-us-btn;
}

.home-container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  @media (min-width: 1024px) {
    padding: 158px 160px 183px 0px;
  }

  @media (max-width: 1024px) {
    background: url("../../assets/images/baby-mobile-img.svg");
    background-size: cover;
    background-repeat: no-repeat;;
    height:100%;
    width:100%;
    padding: 40px 9px 92px 15px;
  }
  .home-content {
    @media (min-width: 1025px) {
      margin-left: 160px;
    }
  }

  .home-main-span-text {
    width: 90.05px;
    height: 22px;
    letter-spacing: 0.01em;
    margin-bottom: 15px;
  }

  .home-shape {
    width: 400px;

    @media (max-width: 1024px) {
      display: none !important;
    }
  }

  .home-main-des-text {
    font-family: "Recoleta";
    font-style: normal;
    font-weight: 400;
    font-size: 55px;
    line-height: 75px;
    margin-bottom: 25px;

    @media (min-width: 1023px) {
      max-width: 600px;
    }

    @media (max-width: 1024px) {
      font-size: 32px;
      line-height: 44px;
    }
  }

  .contact-button-style {
    width: 127px;
    height: 38px;
    background-color: transparent;
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 32.5px;
    border-color: var(--ahsi-text-pink);
    padding-left: 10px;
    padding-right: 10px;
  }

  .book-button-style {
    width: 196.14px;
    height: 38px;
    background-color: var(--ahsi-text-pink);
    color: #ffffff;
    border-radius: 33.5px;
    border-color: var(--ahsi-text-pink);
    padding-left: 10px;
    padding-right: 10px;
  }

  .mt-30 {
    @media (max-width: 1024px) {
      margin-top: 30px;
    }
  }

  .mb-15 {
    @media (max-width: 1024px) {
      margin-bottom: 15px;
    }
  }
}
