@import '../../../App.scss';
.ahsi-section {
    @include section-padding;

    .ahsi-sub-section {
        .sub-section-pink-text {
            @include sub-text;
            font-weight: 400;
            line-height: 22px;
        }

        .sub-section-text {
            @include title-text;
            margin-top: 13px;
            font-weight: 400;
            line-height: 49px;
        }

    }

    .ahsi-doctor-section {
        margin-top: 92px;

        .doctor-detail-section {
            text-align: left;

            .doctor-title{
                @include title-text;
            }

            .doctor-description{
                @include paragraph-text;
            }

            .learn-more-btn {
                @include learn-more-btn;
            }

            .doctor-expertise {
                @include paragraph-text;
                font-weight: 400;
                line-height: 22px;
                margin: 5px 0px 15px 0px;
            }

           
        } 

        .doctor-image-section {
            img {
                @media (max-width: 1024px) {
                    width: 313px;
                    height: 319px;
                    margin-bottom: 38px;
                }
            }
        }
    }
}