@import '../../App.scss';

.bg-light-pink {
    background-color: var(--ahsi-pink-background);
}

.copyrights {
    font-family: 'DM Sans';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    color: var(--ahsi-text-black);
    opacity: 0.7;
    padding-bottom: 40px;
}

.ahsi-footer {
    @include section-padding;
    
    @media (max-width: 1440px) and (min-width: 1025px) {
        padding-left: 10% !important;
        padding-right: 10% !important;
    }

    .mb-50 {
        @media (max-width: 1024px) {
            margin-bottom: 50px;
        }
    }

    .footer-section-left {
        max-width: 400px;

        .title {
            font-family: 'Recoleta';
            font-weight: 400;
            font-size: 20px;
            line-height: 27px;
            max-width: 229px;
        }

        .description {
            font-family: 'DM Sans';
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            margin: 27px 0px;
        }

        .social-icons {
            .social-img-bg {
                background: #FFF;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                margin-right: 19px;
            }
        }
    }

    .footer-section-right {
        .footer-right-sub-section {
            @media (max-width: 1024px) {
                width: 23%;
            }
        }

        .mr-87 {
            @media (min-width: 1024px) {
                margin-right: 87px;
            }
        }

        .title {
            font-family: 'Recoleta';
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 52px;
        }

        .list-without-bullets {
            list-style: none;
            padding-left: 0px;

            li {
                margin-bottom: 25px;

                @media (max-width: 1024px) {
                    width: 130%;
                }
            }

            li>a {
                color: var(--ahsi-text-black);
                text-decoration: none;
                font-family: 'DM Sans';
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                letter-spacing: 0.01em;
            }
        }
    }
}