@import "@fontsource/dm-sans";

.App {
  text-align: center;
}
.ahsi {
  font-family: "DM Sans";
}

.mb-59 {
  @media (max-width: 768px) {
      margin-bottom: 3rem;
  }
}

@mixin sub-text {
  color: var(--ahsi-text-pink);
  font-size: 14px;
}
@mixin paragraph-text {
  font-family: "DM Sans";
  font-size: 14px;
  color: var(--ahsi-text-black);
}
@mixin title-text {
  font-family: "Recoleta";
  color: var(--ahsi-text-black);
  font-size: 24px;

  @media (min-width: 1024px) {
    font-size: 36px;
  }
}

@mixin section-padding {
  @media (min-width: 1024px) {
    padding: 5.667% 12%;
  }

  @media (max-width: 1023px) and (min-width: 768px) {
    padding: 3% 8%;
  }

  @media (max-width: 767px) and (min-width: 320px) {
    padding: 9% 5%;
  }
}

@mixin desktop-only {
  @media (min-width: 1366px) {
    display: none !important;
  }
}

@mixin mobile-only {
  @media (max-width: 1024px) {
    display: none !important;
  }
}

@mixin learn-more-btn {
  width: 152px;
  height: 38px;
  border: 1px solid #F55FA3;
  border-radius: 32.5px;
  background-color: white;
  color: #014293;

  @media (max-width: 1024px) {
    width: 320px;
  }
}

@mixin contact-us-btn {
  min-width: 127.09px;
  height: 38px;
  border: 1px solid #FFFFFF;
  border-radius: 32.5px;
  background-color: transparent;
  color: white;

  @media (max-width: 1024px) {
    width: 320px;
    background-color: white;
    color:var(--ahsi-text-pink);
  }
}